/* 2.5rem = 40px */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

body {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  font-family: "Work Sans", sans-serif;
}

#root {
  width: 100%;
}
